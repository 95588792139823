import styled from '@emotion/styled'

import { AutoplacementData, TooltipPlacement, TooltipSize } from './TooltipTypes'
import { getTooltipPositionStyle } from './TooltipUtils'

export const Container = styled.div<{ size: TooltipSize }>`
  display: flex;
  overflow: hidden;
  pointer-events: none;
  flex-direction: column;
  align-items: flex-start;
  padding: ${({ size }) => (size === 'big' ? '1.5rem 1.75rem' : '1rem 2rem')};
  background: ${({ theme }) => theme.colors.gray[0]};
  color: ${({ theme }) => theme.colors.gray[1000]};
  border-radius: 0.5rem;
  font-size: ${({ theme }) => theme.text.fontSize.m};
  font-weight: ${({ theme }) => theme.text.fontWeight.medium};
  line-height: ${({ theme }) => theme.text.lineHeight.normal};
  letter-spacing: ${({ theme }) => theme.text.letterSpacing.normal};
  filter: drop-shadow(${({ theme }) => theme.commons.shadow.m});
  overflow: hidden;

  & > *:not(:last-child) {
    margin-bottom: 1.125rem;
  }

  &::before {
    content: '';
    position: absolute;
    bottom: -1rem;
    left: 0;
    width: 100%;
    height: 2rem;
  }
`

export const ArrowWrapper = styled.div<{
  placementData: TooltipPlacement | AutoplacementData
  size: TooltipSize
}>`
  position: absolute;
  z-index: 1;
  height: ${({ size }) => (size === 'big' ? 12 : 8)}px;
  width: ${({ size }) => (size === 'big' ? 24 : 16)}px;
  svg {
    width: ${({ size }) => (size === 'big' ? 24 : 16)}px;
    height: ${({ size }) => (size === 'big' ? 12 : 8)}px;
  }
  ${({ placementData, size }) => getTooltipPositionStyle(placementData, size)}
`

export const TooltipTrigger = styled.button`
  height: auto;
  display: inline-flex;
`

export const TooltipArrow = styled.div`
  --arrow-size: 1rem;
  --arrow-background: ${({ theme }) => theme.colors.gray[0]};
  filter: drop-shadow(${({ theme }) => theme.commons.shadow.m});
`
export const TooltipArrowTip = styled.div``
export const TooltipContent = styled.div<{ size?: TooltipSize }>`
  background-color: ${({ theme }) => theme.colors.gray[0]};
  max-width: calc(var(--available-width) - 2 * ${({ size }) => (size === 'big' ? '1.75rem' : '2rem')});
  min-width: unset !important;

  padding: ${({ size }) => (size === 'big' ? '1.5rem 1.75rem' : '1rem 2rem')};
  background: ${({ theme }) => theme.colors.gray[0]};
  color: ${({ theme }) => theme.colors.gray[1000]};
  border-radius: 0.5rem;
  font-size: ${({ theme }) => theme.text.fontSize.m};
  font-weight: ${({ theme }) => theme.text.fontWeight.medium};
  line-height: ${({ theme }) => theme.text.lineHeight.normal};
  letter-spacing: ${({ theme }) => theme.text.letterSpacing.normal};
  filter: drop-shadow(${({ theme }) => theme.commons.shadow.m});

  & > *:not(:last-child) {
    margin-bottom: 1.125rem;
  }
`

export const DefaultTooltipContainer = styled.div`
  z-index: 1 !important;
`
