import dayjs from 'dayjs'
import { ParsedUrlQuery } from 'querystring'

import { HeroShortcut } from '~/components/Home/HomePage/Hero'
import { ClubConfig, getClubConfig } from '~/config/clubConfig'
import { FestivalCodesEnum } from '~/generated/graphql'
import { SearchSettings } from '~/shared/molecules/Search'

import { externalPaths, paths } from './paths'

export const MIN_NUMBER_OF_ATTENDEES = 1
export const MAX_NUMBER_OF_ATTENDEES = 8
export const INITIAL_NUMBER_OF_ATTENDEES = 2

export const START_SLOT = 750
export const END_SLOT = 1380
export const DEFAULT_SLOT = 1140
export const SLOT_DURATION = 90
export const SLOT_OFFSET = 15

export const EDIT_MODE_EXPIRATION_TIME = 900

const defaultRegion = getClubConfig().defaultRegion
export const DEFAULT_LOCATION = {
  label: defaultRegion.name,
  value: defaultRegion.id,
}

const almostMidnight = dayjs().hour() >= 21
const DEFAULT_DATE = almostMidnight ? dayjs().add(1, 'day') : dayjs()
export const DEFAULT_SLOT_COMPUTED = almostMidnight ? 60 : dayjs().hour() * 60 + 150 || DEFAULT_SLOT

export const defaultSearchSettings = ({
  untypedQuery = {},
  defaultDate = DEFAULT_DATE,
  defaultSlot,
  defaultPeopleCount = INITIAL_NUMBER_OF_ATTENDEES,
  forceParams = true,
}: {
  untypedQuery?: ParsedUrlQuery
  defaultDate?: dayjs.Dayjs
  defaultSlot?: number
  defaultPeopleCount?: number
  forceParams?: boolean
} = {}): SearchSettings => {
  const query = untypedQuery as Record<string, string>
  const date = query?.date ? dayjs.parseZone(query.date) : forceParams ? defaultDate : undefined
  const slot = query?.slot ? parseInt(query.slot, 10) : forceParams ? defaultSlot : undefined

  return {
    peopleCount: (query?.peopleCount && parseInt(query?.peopleCount, 10)) || defaultPeopleCount,
    name: query?.name || '',
    date,
    slot,
  }
}

export const FESTIVAL_CODE_TO_SLUG = {
  gw: 'ginweek',
  rw: 'restaurantweek',
  fdw: 'finediningweek',
  bw: 'breakfastweek',
  pw: 'pizzaweek',
}

export const FESTIVAL_EDITION_STATES = {
  VOUCHER: 'VOUCHER',
  PRESALE: 'PRESALE',
  SALE: 'SALE',
  UNAVAILABLE: 'UNAVAILABLE',
}
export const SLOTS_FETCH_INTERVAL = 300000 // = 5min

export const RESERVABLE_TYPES = {
  EVENT: 'Event',
  DAILY: 'Daily',
  FER: 'FestivalEditionRestaurant',
  EXTRA: 'ReservableExtra',
} as const

export const CAREER_LINK = 'https://restaurantclub.freshteam.com/jobs'

export const ALPHABET = 'ABCDEFGHIJKLMN'

export const MAPBOX_LINK = 'https://cdn-osm.pullit.pl/styles/v1/restaurantweek/ck2q4mbc00dem1cp791flcoda/tiles/{z}/{x}/{y}?access_token='

export const REGULATION_STATUS = {
  ACCEPTED: 'ACCEPTED',
  EXPIRED: 'EXPIRED',
  NOT_ACCEPTED: 'NOT_ACCEPTED',
}

export const APOLLO_STATE_PROP_NAME = '__APOLLO_STATE__'

export const COOKIE = {
  LOCATION: 'location',
  NEXT_LOCALE: 'NEXT_LOCALE',
  ADULT: 'adult',
  OPTANON_CONSENT: 'OptanonConsent',
  ALERT_BOX_CLOSED: 'OptanonAlertBoxClosed',
}

export const DEFAULT_REGION = getClubConfig().defaultRegion

export const HOME_HERO_SHORTCUTS = (isDemo: boolean): HeroShortcut[] => [
  {
    url: externalPaths.rwLandingPage,
    title: 'RestaurantWeek®',
  },
  {
    url: paths.chefsMenuOffers,
    title: 'Discover Chef’s Menu',
    badge: {
      color: 'green',
      label: 'NEW',
    },
    enabledForFeatureFlag: 'chmEnabled',
  },
  {
    url: externalPaths.bwLandingPage,
    title: 'BreakfastWeek',
  },
]

export const isShortcutEnabled = (clubConfig: ClubConfig) => (shortcut: HeroShortcut) => {
  return typeof shortcut.enabledForFeatureFlag === 'undefined' || clubConfig[shortcut.enabledForFeatureFlag]
}

export const HEADERS = {
  X_LANGUAGE: 'x-language',
}

export const LOWEST_VOUCHER_PRICES: Record<string, number> = {
  FDW24: 295,
  RWP24W: 119,
}

export const RESTAURANT_FESTIVAL_MENU_FESTIVAL = FestivalCodesEnum.Rw

export const ALL_DAY_SLOT_HOUR = 'Walk-in'
