import { StatusMessages } from '~/components/Checkout/SuccessPage/StatusBox/StatusBoxUtils'
import { FestivalCodesEnum, Region } from '~/generated/graphql'
import { gt as GT } from '~/locale'
import { FestivalSalesOffer } from '~/types/festival'

import { voucherImages } from './pl/assets/pageImages'
import { voucherTranslation } from './pl/copy/pageTranslate'

export type MarketCode = 'pl' | 'cz' | 'de'

export type ClubConfigCopy = {
  rwReservationStatusMessages: (gt: typeof GT) => StatusMessages
  giftStatusMessages: (gt: typeof GT) => StatusMessages
  beforeYouVisitTitle: (gt: typeof GT) => string
  beforeYouVisitDefaultDescription: (gt: typeof GT) => string
  voucher: typeof voucherTranslation
}

export type ClubConfigAssets = {
  voucher: typeof voucherImages
}

export type Logos = Partial<Record<FestivalCodesEnum | 'club', { centered: string; left: string }>>

export type ClubConfig = {
  aboutPageEnabled: boolean
  assets: ClubConfigAssets
  blikEnabled: boolean
  blogEnabled: boolean
  careerPageEnabled: boolean
  chmEnabled: boolean
  copy: ClubConfigCopy
  customRwSuccessCopy: boolean
  d2dEnabled: boolean
  defaultGeolocation: { latitude: number; longitude: number }
  defaultRegion: Pick<Region, 'id' | 'name'>
  directCardPaymentEnabled: boolean
  enabledLanguages: Record<string, string>
  extraMarketingConsentEnabled: boolean
  festivalSalesOffer: FestivalSalesOffer
  footerAwardsEnabled: boolean
  klarnaEnabled: boolean
  logos: Logos
  otherFestivalsEnabled: boolean
  paypoEnabled: boolean
  redirectHeroToLandingEnabled: boolean
  restaurantsListingEnabled: boolean
  showFooterCopyright: boolean
  sideEventListingPageEnabled: boolean
  useRWInsteadOfRC: boolean
  visaMobileEnabled: boolean
  hideRCInfo: boolean
}

// voucher ____________________________________________________________________________

// /assets

type BannerBreakpoint = {
  desktop: string
  mobile: string
}

export enum EventsListingRoutes {
  GiftEventsListing = 'gift-events-listing',
  SideEventsListing = 'side-events-listing',
}

export type GiftEventsListingBanners = {
  [EventsListingRoutes.GiftEventsListing]: {
    mainBanner: BannerBreakpoint
    bottomBanner?: BannerBreakpoint
  }
  [EventsListingRoutes.SideEventsListing]: {
    mainBanner: BannerBreakpoint
    bottomBanner?: BannerBreakpoint
  }
}

// _____________________________________________________________________________________
