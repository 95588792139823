import { formatMenuLabel } from '~/utils/graphqlDataFormatters'

import { FullMenuFragment } from '~/generated/graphql'

import { Content, Description, PositionWrapper, Title, Wrapper } from './MenuShards'

interface Props {
  menu: FullMenuFragment
  index: number
  withTitle?: boolean
  forceLabels?: boolean
}

const MenuView = ({ menu, withTitle = true, index, forceLabels }: Props) => {
  const { id, label, positions } = menu
  return (
    <Wrapper>
      {withTitle && <Title key={id}>{formatMenuLabel(index, label, forceLabels)}</Title>}
      {positions.map((position, idx) => (
        <PositionWrapper key={position.name + idx}>
          <Description>{position.name.toUpperCase()}:</Description>
          <Content>{position.content}</Content>
        </PositionWrapper>
      ))}
    </Wrapper>
  )
}

export default MenuView
