import { ReactElement, ReactNode, useEffect, useMemo, useRef } from 'react'

import _ from 'lodash'
import type { NextPage } from 'next'
// import { Integrations } from '@sentry/tracing'
// import get from 'lodash/get'
import NextApp, { AppContext, AppInitialProps, AppProps } from 'next/app'
import Script from 'next/script'

// import Script from 'next/script'
import { ApolloProvider } from '@apollo/client'
import { ThemeProvider } from '@emotion/react'
import { GoogleOAuthProvider } from '@react-oauth/google'

import 'flag-icons/css/flag-icons.min.css'

import '~/utils/logger'
import extendDayjs from '../utils/dayjs'
import { getCookie } from '~/utils/cookie'
import { processEnv } from '~/utils/env'
import { hasWindow, supportsIntersectionObserver } from '~/utils/helpers'
import useApolloClient from '~/utils/hooks/useApolloClient'
import yup, { validationMessages } from '~/utils/validationMessages'

// import Error from '>/pages/_error'
import locales from '>/locale/dist'
import config from '~/config'
import { COOKIE, HEADERS } from '~/constants'
import Layout from '~/layout'
import { gt } from '~/locale'
import { loadLocales } from '~/locale/loader'
import { CouponBar } from '~/shared/atoms/CouponBar/CouponBar'
import LoadingCursor from '~/shared/atoms/LoadingCursor'
import { ToastProvider } from '~/shared/atoms/Toast'
import { CookieConsentComponent } from '~/shared/molecules/CookieConsent/CookieConsentView'
import { ModalProvider } from '~/shared/molecules/Modal'
import theme from '~/theme'

extendDayjs(processEnv.DEFAULT_LANG)

// const handleRouteChange = async (router) => {
//   hasWindow() && (window as any).gtag && (window as any).gtag('config', UA_KEY, {
//     page_path: router.asPath
//   })
// }

interface Props extends AppProps {
  err: any
  requestHeader: Record<string, string>
  preloadedLocale?: {
    name: string
    messages: Record<string, string>
  }
}

export type NextPageWithLayout<P = {}, IP = P> = NextPage<P, IP> & {
  getLayout?: (page: ReactElement<P>) => ReactNode
}

type AppPropsWithLayout = Props & {
  Component: NextPageWithLayout
}

type GetInitialProps = ({ Component, ctx }: AppContext) => Promise<AppInitialProps>

const App: React.FC<Props> & { getInitialProps: GetInitialProps } = ({
  router,
  Component,
  pageProps,
  err,
  requestHeader,
  preloadedLocale,
}: AppPropsWithLayout) => {
  const debugLogger = getLogger('_app', 'debug')
  const willMount = useRef(true)
  const ctx = useMemo(
    () => ({
      req: {
        headers: {
          ...requestHeader,
          [HEADERS.X_LANGUAGE]: router.locale,
        },
      },
    }),
    [requestHeader, router.locale]
  )
  const apolloClient = useApolloClient(pageProps.apolloState, ctx)

  // componentWillMount substitution
  if (willMount.current) {
    if (preloadedLocale) {
      loadLocales(preloadedLocale.name, preloadedLocale.messages)
    }

    if (hasWindow() && !supportsIntersectionObserver()) {
      require('intersection-observer')
    }
    willMount.current = false
  }

  useEffect(() => {
    // if (hasWindow() && typeof window?.KlarnaOnsiteService !== 'undefined') {
    //   window.KlarnaOnsiteService.push({ eventName: 'refresh-placements' })
    // }
  }, [router])

  // useEffect(() => {
  //   if ('serviceWorker' in navigator) {
  //     navigator.serviceWorker.register('/_next/static/service-worker.js')
  //   }

  //   // handleRouteChange(router)
  //   // router.events.on('routeChangeComplete', () => handleRouteChange(router))
  //   // return () => router.events.off('routeChangeComplete', () => handleRouteChange(router))
  // }, [router])

  // const sentryTransaction = () => {
  //   const transaction = Sentry.getCurrentHub()
  //     .getScope()
  //     .getTransaction()

  //   if (transaction === undefined) {
  //     const transaction = Sentry.startTransaction({
  //       op: 'transaction',
  //       name: 'App'
  //     })
  //     Sentry.configureScope(scope => {
  //       scope.setSpan(transaction)
  //     })
  //   }

  //   return transaction
  // }

  // const sentryTraceId = () => {
  //   const transaction = sentryTransaction()
  //   if (transaction) {
  //     return transaction.toTraceparent()
  //   }

  //   return ''
  // }

  // Workaround for https://github.com/zeit/next.js/issues/8592
  const modifiedPageProps = { ...pageProps, err, router }

  useEffect(() => {
    extendDayjs(router.locale).then(() => debugLogger(`Extended dayjs locale ${router.locale}`))

    yup.setLocale(validationMessages(gt))

    if (preloadedLocale?.name !== router.locale) {
      loadLocales(router.locale).then(() => debugLogger(`Selected locale ${router.locale}`))
    }
  }, [router.locale, preloadedLocale?.name])

  // if (get(pageProps, 'err.statusCode') === 404) {
  //   return <Error statusCode={pageProps.err.statusCode} hasGetInitialPropsRun={false} err />
  // }

  const renderWithLayout =
    Component.getLayout ||
    function (page) {
      return <Layout>{page}</Layout>
    }

  const GTM_KEY = processEnv?.GTM_KEY

  return (
    <>
      {GTM_KEY && (
        <Script async id='google-analytics'>
          {`(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
        new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
        j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
        'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
        })(window,document,'script','dataLayer','${GTM_KEY}');`}
        </Script>
      )}
      <Script id='hotjar'>
        {`(function(h,o,t,j,a,r){
          h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
          h._hjSettings={hjid:1993069,hjsv:6};
          a=o.getElementsByTagName('head')[0];
          r=o.createElement('script');r.async=1;
          r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
          a.appendChild(r);
        })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=')`}
      </Script>
      {hasWindow() && config().enableKlarna && config().klarna.client_id && (
        <Script defer async src={config().klarna.sdk} data-client-id={config().klarna.client_id} />
      )}

      {config().commonNinjaWidgetIds.map(id => (
        <CouponBar key={id} widgetId={id} />
      ))}

      <ApolloProvider client={apolloClient}>
        <GoogleOAuthProvider clientId={config().googleClientId}>
          <ThemeProvider theme={theme}>
            <ToastProvider>
              <ModalProvider>
                <LoadingCursor />
                <CookieConsentComponent />
                {renderWithLayout(<Component {...modifiedPageProps} />)}
              </ModalProvider>
            </ToastProvider>
          </ThemeProvider>
        </GoogleOAuthProvider>
      </ApolloProvider>
    </>
  )
}

App.getInitialProps = async appContext => {
  const { ctx, router } = appContext
  const { req, res } = ctx
  const debugLogger = getLogger('_app', 'debug')
  const appProps = await NextApp.getInitialProps(appContext)
  const headers = appContext?.ctx?.req?.headers || {}
  const isSSR = !!appContext?.ctx?.req
  const isDataRequestFromClient = ctx.req?.url?.includes('/_next/data')

  const hasLocaleHeader = !!getCookie(req, COOKIE.NEXT_LOCALE)
  if (!hasLocaleHeader && isSSR && res) {
    const nextLocaleCookie = `${COOKIE.NEXT_LOCALE}=${router?.locale || processEnv.DEFAULT_LANG}`
    res.setHeader('set-cookie', [nextLocaleCookie])
    debugLogger(`Set cookie locale ${nextLocaleCookie}`)
  }

  const preloadedLocaleMessages = router?.locale && !isDataRequestFromClient && (await locales[router.locale]())

  return {
    pageProps: {
      ...appProps.pageProps,
    },
    reqestHeader: _.omit(headers, ['cookie', 'authorization']),
    preloadedLocale: preloadedLocaleMessages && {
      name: router.locale,
      messages: { [router.locale]: preloadedLocaleMessages[router.locale] },
    },
  }
}

export default App
