import { gt } from '~/locale'

const { tp } = gt

export const errors = {
  code_401: gt.tp('Error', 'Access denied'),
  code_404: gt.tp('Error', 'Not found'),
  code_500: gt.tp('Error', 'Network error. Try again later'),
  blocked: 'Account has been blocked.',
  common_error: tp('Error', 'Ooops! Something went wrong. Try again or contact us directly at info@restaurantclub.pl'),
  first_and_last_name_required: 'Please provide first and last name',
  network_error: tp('Error', 'Network error. Try again later'),
  record_not_found: tp('Error', 'There is no such email in our database'),
  email_not_found: tp('Error', 'There is no such email in our database'),
  unauthorized: tp('Error', 'Email or password is incorrect'),
  unverified_user: tp('Error', 'Please verify your email'),
  unknown_error: tp('Error', 'Ooops! Something went wrong. Try again or contact us directly at info@restaurantclub.pl'),
  user_already_exists: tp('Error', 'User already exists.'),
  reservation_already_assigned: tp('Error', 'Reservation already assigned to user'),
  cannot_add_card: 'This card cannot be added. Try again later.',
  cannot_remove_card: 'This card cannot be removed.',
  cannot_set_default: "You can't set this card as default.",
  invalid_expiry_date: 'Expiry date is invalid',
  invalid_cardholder: 'Cardholder name is invalid',
  invalid_cvv: 'Credit card code is invalid',
  invalid_card_number: 'Credit card number is invalid',
  max_number_of_allowed_guests_reached: 'This reservation already has the maximum number of guests',
  blik_length: 'BLIK code must be 6 characters long',
  reservable_extras_unavailable: 'This #ChefsMenu is not available at the selected time',
  no_presale_code: 'Enter the early-reservation code to proceed',
  validation: {
    mixed: {
      integers_only: 'This field may contain only numbers',
      letters_only: 'This field may contain only letters',
      no_spaces: 'This field cannot contain spaces',
      mixed_case: 'This field must contain a mix of uppercase and lowercase characters',
    },
    email: {
      invalid: tp('Error', 'Incorrect email format'),
      taken: tp('Error', 'This email is already taken'),
    },
    password: {
      blank: 'Password is required',
      invalid: 'Incorrect password format',
      too_short: 'Password is too short',
    },
    phone_number: {
      invalid: 'Incorrect phone number format',
    },
    vat_id: {
      invalid: tp('Error', 'VAT ID is invalid'),
      length: tp('Error', 'VAT ID must be 10 characters long.'),
      user_id: {
        taken: tp('Error', 'This user ID is already taken'),
      },
    },
    discount: {
      discount_already_used: 'This discount code can only be used once.',
      has_no_remaining_uses: 'Usage limit for this code has been reached.',
      discountable_object_mismatch: 'The used code is designated for a different Festival.',
      discountable_user_mismatch: 'This discount code is assigned to another guest account.',
      does_not_match_slot: 'Your discount code applies for reservations at a different time of day.',
      allowed_people_count_exceeded: 'Your discount code applies for reservations of larger tables.',
      does_not_match_reservation_source: 'This discount code does not apply for this type of reservation source.',
      discount_cannot_be_used_this_day_of_week: 'Your access-discount code is valid for reservations on a different day of the week.',
      discount_requires_marketing_consent: 'Requires marketing consent.',
      can_be_used_in_mobile_app_only: 'This discount code is available in mobile apps only.',
      disabled: 'Discount code is disabled.',
    },
    menus: {
      invalid: "Menus count doesn't correspond with people count multiplied by menus per person",
    },
  },
  discount_not_found: 'The used code does not exist, please check the spelling.',
}

const marketingModalTriggerErrorCodes = ['requires_marketing_consent', 'discount_requires_marketing_consent']
export const shouldTriggerMarketingModal = (errorCodes: string[]) => marketingModalTriggerErrorCodes.some(code => errorCodes.includes(code))
