// @ts-strict-ignore
import { useState } from 'react'
import MaskedInput from 'react-text-mask'

import { useTheme } from '@emotion/react'

import { Body2, Heading6 } from '~/theme/utils/typography'

import Icon from '~/shared/atoms/Icon'

import { ActionsWrapper, Container, IconWrapper, StyledInput, Wrapper } from './InputShards'
import { Props } from './InputTypes'

const Input: React.FC<Props> = props => {
  const theme = useTheme()
  const { error, errorMessage, title, description, mask, iconLeft, actionsRight = [], type, disabled, ...inputProps } = props
  const [showPassword, setShowPassword] = useState(false)
  const inputType = type === 'password' && showPassword ? 'text' : type

  return (
    <>
      <Wrapper props={props}>
        {title && <Heading6 as={'div'}>{title}</Heading6>}
        {description && <Body2 fontWeight={'medium'}>{description}</Body2>}
        <Container props={props}>
          {iconLeft && <IconWrapper>{iconLeft}</IconWrapper>}
          <StyledInput {...inputProps} as={mask ? MaskedInput : 'input'} {...props} type={inputType} />
          <ActionsWrapper>
            {actionsRight}
            {type === 'password' && (
              <IconWrapper filter={theme.filters.gray[800]} onClick={() => setShowPassword(!showPassword)}>
                <Icon icon={showPassword ? 'eye-hide' : 'eye-show'} />
              </IconWrapper>
            )}
            {error && !disabled && (
              <IconWrapper filter={theme.filters.red[400]}>
                <Icon icon={'attention'} />
              </IconWrapper>
            )}
          </ActionsWrapper>
        </Container>
        {error && errorMessage && !disabled && <Body2 fontWeight={'medium'}>{errorMessage}</Body2>}
      </Wrapper>
    </>
  )
}

export default Input
