// @ts-strict-ignore
import dayjs from 'dayjs'

import { dayjsToString } from '~/utils/graphqlDataFormatters'

import { OTHER_DATES_RESTAURANTS_LIMIT } from '~/components/Listing/ListingPageContent/ListingPageContentUtils'
import { addFringeSlots } from '~/components/Reservation/SlotSelectionPanel/SlotSelectionPanelUtils'
import {
  OtherDatesRestaurantsQuery,
  ReservableEnum,
  RestaurantsListQueryVariables,
  useOtherDatesRestaurantsQuery,
} from '~/generated/graphql'

import { FilterSettings } from '../../ListingPageContent'
import { AllQueryVariables } from '../../ListingPageContent/ListingPageContentTypes'

export const PAGE_SIZE = 3

export const defaultFilterSettings: FilterSettings = {
  tags: [],
  sort: undefined,
  chefsMenu: false,
  region: undefined,
}

export const getRestaurantListQueryVariables = ({
  selectedRegion,
  filters,
  search,
  tagsAndNames,
  endCursor,
  first,
  reservableFrom,
  endsOn,
  ids,
}: AllQueryVariables): RestaurantsListQueryVariables => {
  const { tagIds } = getTagAndRestaurantIds(tagsAndNames)
  const allTagIds = [...filters.tags.map(({ id }) => id), ...tagIds]
  const neighboringSlots = addFringeSlots([search.slot], 30, 5)
  const { date } = search

  const rangeStart = date || dayjs.parseZone(reservableFrom)
  const rangeEnd = date || endsOn

  return {
    region_id: selectedRegion?.id,
    sort: filters.sort,
    reservation_filters: {
      startsOn: dayjsToString(rangeStart),
      endsOn: dayjsToString(rangeEnd),
      hours: [...neighboringSlots],
      peopleCount: search.peopleCount,
      reservableType: ReservableEnum.Daily,
    },
    after: endCursor,
    first: first,
    ...(allTagIds.length && { tag_ids: allTagIds }),
    ...(ids !== undefined ? { ids } : {}),
    ...('chefsMenu' in filters && filters.chefsMenu && { with_reservable_extras: true }),
  }
}

export const getTagAndRestaurantIds = tagsAndNames => {
  const tagIds = []
  const restaurantIds = []
  tagsAndNames &&
    tagsAndNames.forEach(({ __typename, id }) => {
      __typename === 'Tag' && tagIds.push(id)
      __typename === 'Restaurant' && restaurantIds.push(id)
    })
  return {
    tagIds,
    restaurantIds,
  }
}

// TODO: uncomment after release 16.12
export const useRestaurantsInOtherDates = ({ filters }: { filters: AllQueryVariables }) => {
  // eslint-disable-next-line no-unused-vars
  const { data } = useOtherDatesRestaurantsQuery({
    variables: getRestaurantListQueryVariables({ ...filters, first: OTHER_DATES_RESTAURANTS_LIMIT }),
  })

  return { otherDatesRestaurants: { totalCount: 0, nodes: [] } as OtherDatesRestaurantsQuery['otherDatesRestaurants'] }
}
