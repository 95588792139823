// @ts-strict-ignore

import dayjs from 'dayjs'

import { dayjsToString } from '~/utils/graphqlDataFormatters'

import { OTHER_DATES_RESTAURANTS_LIMIT } from '~/components/Listing/ListingPageContent/ListingPageContentUtils'
import {
  FerListQueryVariables,
  FestivalCodesEnum,
  FestivalEdition,
  OtherDatesFeRsQuery,
  ReservableEnum,
  useOtherDatesFeRsQuery,
} from '~/generated/graphql'
import { MetadataType } from '~/layout/Head/HeadTypes'
import { gt } from '~/locale'

import { AllQueryVariables, FilterSection } from '../../ListingPageContent/ListingPageContentTypes'
import { getTagAndRestaurantIds } from '../RestaurantListingPage/RestaurantListingPageUtils'

export const getRangeStart = (startsOn?: dayjs.Dayjs, reservableFrom?: dayjs.Dayjs): dayjs.Dayjs => {
  if (!startsOn && !reservableFrom) return dayjs.parseZone().add(1, 'day')
  if (!startsOn || !reservableFrom) return startsOn || reservableFrom
  return startsOn.clone().isAfter(reservableFrom, 'hour') ? startsOn : reservableFrom
}

export const getFERListQueryVariables = ({
  selectedRegion,
  filters,
  search,
  festivalEditionId,
  endCursor,
  tagsAndNames,
  reservableFrom,
  endsOn,
  first,
  ids,
}: AllQueryVariables): FerListQueryVariables => {
  const { tagIds } = getTagAndRestaurantIds(tagsAndNames)
  const allTagIds = [...filters.tags.map(({ id }) => id), ...tagIds]
  const { date, slot, peopleCount } = search || {}

  const rangeStart = date || getRangeStart(dayjs.parseZone(reservableFrom))
  const rangeEnd = date || endsOn

  return {
    region_id: selectedRegion.id,
    festival_edition_ids: festivalEditionId,
    reservation_filters: {
      startsOn: dayjsToString(rangeStart),
      endsOn: dayjsToString(rangeEnd),
      hours: slot ? [slot] : [],
      peopleCount: peopleCount,
      reservableType: ReservableEnum.FestivalEditionRestaurant,
    },
    ...(allTagIds.length && { tag_ids: allTagIds }),
    ...(ids !== undefined ? { ids } : {}),
    first: first,
    after: endCursor,
  }
}

export const getFilterSections = ({
  festivalCode,
  isExternalSale,
}: {
  festivalCode: FestivalCodesEnum
  isExternalSale: boolean
}): FilterSection[] => {
  if (isExternalSale) {
    return []
  }

  switch (festivalCode) {
    case FestivalCodesEnum.Gw:
      return ['popular']
    default:
      return ['popular', 'cuisines', 'dishes']
  }
}

export const getFestivalMetadata = ({ festivalCode }: { festivalCode: FestivalCodesEnum }): MetadataType => {
  switch (festivalCode) {
    case FestivalCodesEnum.Rw:
      return {
        title: gt.tp('ListingPage', 'RestaurantWeek® | Top ticketed festival in Poland'),
        description: gt.tp(
          'ListingPage',
          'Flagship 3 courses + cocktail from 69,99* at top restaurants! Join nearly 2 million Guests and discover restaurants at their best (*+fee 5,98 PLN).'
        ),
      }
    case FestivalCodesEnum.Fdw:
      return {
        title: gt.tp('ListingPage', 'FineDiningWeek® | Festival of Restaurant Art'),
        description: gt.tp(
          'ListingPage',
          'A masterful 3/5 dishes + cocktail at a festival price - in the most outstanding (including Michelin-starred) restaurants in Poland! Discover Polish fine dining!'
        ),
      }
    case FestivalCodesEnum.Bw:
      return {
        title: gt.tp('ListingPage', 'BreakfastWeek | Trendy breakfasts in the city!'),
        description: gt.tp(
          'ListingPage',
          'Signature breakfasts + coffee as a gift in the trendiest places. Discover, book and skip the queues in your neighbourhood and across the city!'
        ),
      }
    default:
      return {}
  }
}

// TODO: uncomment after release 16.12
export const useFersAvailableInOtherDates = ({
  filters,
  festivalEditionId,
}: {
  filters: AllQueryVariables
  festivalEditionId: FestivalEdition['id']
}) => {
  // eslint-disable-next-line no-unused-vars
  const { data } = useOtherDatesFeRsQuery({
    variables: getFERListQueryVariables({
      ...filters,
      festivalEditionId: festivalEditionId,
      first: OTHER_DATES_RESTAURANTS_LIMIT,
    }),
  })

  return { otherDatesRestaurants: { totalCount: 0, nodes: [] } as OtherDatesFeRsQuery['otherDatesRestaurants'] }
}
