// @ts-strict-ignore
import { useEffect, useState } from 'react'
import { useMediaQuery } from 'react-responsive'

import dayjs from 'dayjs'
import Image from 'next/image'
import { useRouter } from 'next/router'

import { mediaQueryIsMobile, mediaQueryIsTablet } from '~/theme/utils/grid'
import { Heading5, Heading6 } from '~/theme/utils/typography'
import { deleteCookie, getCookieAsParsedJson } from '~/utils/cookie'
import { formatErrorMessages, getErrorCodes } from '~/utils/formatErrorMessages'
import { dayjsToString, formatMenuLabel, formatPrice, getPossibleFERSlots, getSlotFromTime } from '~/utils/graphqlDataFormatters'
import { runEcommerceAdd, runEcommerceDetails } from '~/utils/gtag'
import useReservationCreate from '~/utils/hooks/useReservationCreate'
import { imgProxyLoader } from '~/utils/imgproxy'

import { ChefsMenuSelectHeader } from '~/components/Checkout/CheckoutPage/ChefsMenuDetailsModal'
import MarketingConsentModal from '~/components/Checkout/CheckoutPage/MarketingConsentModal'
import { modalProps } from '~/components/Checkout/CheckoutPage/MarketingConsentModal/MarketingConsentModalUtils'
import Menu from '~/components/Checkout/CheckoutPage/shared/Menu'
import { HeaderWrapper } from '~/components/ChefsMenu/ChefsMenuPage/ChefsMenuPageShards'
import { getRangeStart } from '~/components/Listing/pages/FERListingPage/FERListingPageUtils'
import ProductDetailsPanel from '~/components/Product/ProductDetailsPanel'
import SlotSelectionPanel from '~/components/Reservation/SlotSelectionPanel'
import { getDefaultFestivalMenuCounts } from '~/components/Reservation/SlotSelectionPanel/SlotSelectionPanelUtils'
import RestaurantSlider from '~/components/Restaurant/RestaurantPage/RestaurantSlider'
import { SliderImg } from '~/components/Restaurant/RestaurantPage/RestaurantSlider/RestaurantSliderView'
import { StyledToastContainer } from '~/components/User/LoginModal/LoginModalShards'
import { paths, RESERVABLE_TYPES } from '~/constants'
import { errors as errorMessages, shouldTriggerMarketingModal } from '~/constants/errors'
import { ReservableEnum, useCreateFestivalReservationMutation } from '~/generated/graphql'
import { gt } from '~/locale'
import Button from '~/shared/atoms/Button'
import FormattedPrice from '~/shared/atoms/FormattedPrice/FormattedPriceView'
import { useToast } from '~/shared/atoms/Toast'
import { useModal } from '~/shared/molecules/Modal'
import ProductPageTitle from '~/shared/molecules/ProductPageTitle'

import {
  CheckoutCard,
  Details,
  Grid,
  ImageContainer,
  MenuLabel,
  MenuPreface,
  Menus,
  PartnersBox,
  Profile,
  SelectWrapper,
  UpperHeading,
  Wrapper,
} from './FERModalShards'
import { ALL_DAY_SLOT, festivalModalProps } from './FERModalUtils'

interface Props {
  fer: any
  handleClose: () => void
  variant?: 'default' | 'checkout'
  status?: boolean
  setStatus?: (status: boolean) => void
  withCarousel?: boolean
}

const renderSliderItem = (image: SliderImg, restaurantName: string) => (
  <Image
    loader={imgProxyLoader}
    layout='fill'
    src={image?.original || image?.preview}
    objectFit='cover'
    alt={restaurantName}
    objectPosition='center'
  />
)

const FERModalView = ({ fer, handleClose, variant, status: initialStatus, setStatus: handleStatus, withCarousel }: Props) => {
  const { price: customFerPrice, imageFiles, menus, id: reservableId, restaurant, festivalEdition } = fer || {}
  const {
    price: festivalEditionPrice,
    startsAt,
    endsAt,
    reservableFrom,
    forceLabels,
    reservationFeeUnitPrice,
    minPeopleCount,
  } = festivalEdition || {}
  const price = customFerPrice ?? festivalEditionPrice
  const [status, setStatus] = useState(initialStatus)
  const images = imageFiles?.length ? imageFiles : restaurant.imageFiles
  const modal = useModal()
  const isTablet = useMediaQuery({ query: mediaQueryIsTablet })
  const router = useRouter()
  const FestivalName = isTablet ? Heading6 : Heading5
  // const dateRange = getDateRange(startsAt)
  const rangeStart = getRangeStart(dayjs.parseZone(startsAt), dayjs.parseZone(reservableFrom))
  const rangeEnd = dayjs.parseZone(endsAt)

  const isMobile = useMediaQuery({ query: mediaQueryIsMobile })
  const toast = useToast()

  const [presaleCode, setPresaleCode] = useState('')
  const earlyReservation = getCookieAsParsedJson(undefined, 'earlyReservationVoucher')
  const feState = festivalEdition?.state
  const showPresale = feState === 'PRESALE' && (!earlyReservation || earlyReservation.festivalId !== festivalEdition?.id)

  useEffect(() => {
    runEcommerceDetails({
      reservableData: { restaurant, price, festivalEdition },
      reservableType: ReservableEnum.FestivalEditionRestaurant,
    })
  }, [])

  const [createFestivalReservation] = useReservationCreate({
    useCreateReservationMutation: useCreateFestivalReservationMutation,
  })

  const handleClick = () => {
    setStatus(!status)
    handleStatus(status)
  }

  const earliestReservableFrom = dayjs.parseZone(reservableFrom)

  const fromPartners = {
    bw: (
      <>
        <Heading6 fontWeight='medium'>{gt.tp('PartnerMessage', 'Alprocino or Alprolatte.')}</Heading6>
        <Heading6 fontWeight='medium'>{gt.tp('PartnerMessage', 'Enjoy a free cup of coffee from Alpro!')}</Heading6>
      </>
    ),
  }

  const menuPreface = {
    fdw: <MenuPreface fontWeight='semiBold'>{gt.tp('Menu', 'AMUSE-BOUCHE! As a welcome gift, a surprise for the palate')}</MenuPreface>,
  }
  const slotSelect = fer && (
    <SlotSelectionPanel
      header={
        <>
          <HeaderWrapper>
            <ChefsMenuSelectHeader
              price={price}
              additionalPriceInfo={
                reservationFeeUnitPrice
                  ? gt.tp('FestivalModal', '(+ service fee {{ price }})', {
                      price: formatPrice(reservationFeeUnitPrice),
                    })
                  : undefined
              }
            />
          </HeaderWrapper>
          <StyledToastContainer id={'FERToastContainer'} />
        </>
      }
      fieldsToHide={['title', 'submit', 'slot']}
      type={RESERVABLE_TYPES.FER}
      price={price}
      reservableInput={{ id: reservableId, type: ReservableEnum.FestivalEditionRestaurant }}
      possibleSlots={fer && (fer.allDaySlots ? [ALL_DAY_SLOT] : getPossibleFERSlots(fer))}
      allDaySlots={fer.allDaySlots}
      dateRange={{ startsOn: dayjsToString(rangeStart), endsOn: dayjsToString(rangeEnd) }}
      prevParams={undefined}
      defaultDate={earliestReservableFrom}
      defaultSlot={getSlotFromTime(earliestReservableFrom)}
      forceSearchParams={true}
      showPresale={showPresale}
      onPresaleChange={e => setPresaleCode(e.target.value || '')}
      minimumPeopleCount={minPeopleCount}
      onSubmit={async params => {
        const additionalVariables = showPresale
          ? { voucherCode: presaleCode }
          : earlyReservation?.festivalId === festivalEdition?.id
          ? { voucherCode: earlyReservation?.code }
          : {}

        await createFestivalReservation({
          variables: {
            ...params,
            date: dayjsToString(params.date),
            festivalEditionId: festivalEdition.id,
            restaurantId: restaurant.id,
            menus: getDefaultFestivalMenuCounts(menus),
            ...additionalVariables,
          },
          onCompleted: data => {
            router.push(paths.checkoutFestivalMenu(data.createFestivalReservation.code))
            modal.close()

            if (Object.keys(additionalVariables).length > 0) {
              deleteCookie(null, 'earlyReservationVoucher')
            }
          },
          onError: error => {
            let errors = formatErrorMessages(error)
            const errorCodes = getErrorCodes(error)
            if (shouldTriggerMarketingModal(errorCodes)) {
              modal.open(modalProps, <MarketingConsentModal handleAccepted={modal.close} />)
            }

            if (errorCodes.includes('discount_not_found') && showPresale && presaleCode === '') {
              errors = [gt.tp('Error', errorMessages.no_presale_code)]
            }

            errors.map(err =>
              toast.add({
                message: err,
                type: 'error',
                containerSelector: '#FERToastContainer',
                size: 'small',
              })
            )
          },
        })

        runEcommerceAdd({
          reservableData: {
            restaurant,
            festivalEdition,
            price,
            quantity: params.peopleCount,
          },
          reservableType: RESERVABLE_TYPES.FER,
        })
      }}
    />
  )

  const openSlotSelectModal = () => {
    handleClose && handleClose()
    modal.open(
      festivalModalProps,
      <SelectWrapper>
        {slotSelect}
        {/* <Infobox /> */}
      </SelectWrapper>
    )
  }

  const festivalCode = festivalEdition.festival.code

  const contentFromPartners = fromPartners[festivalCode]
  const festivalMenuPreface = menuPreface[festivalCode]

  return (
    <Wrapper>
      <Grid>
        <Profile>
          <ImageContainer>
            {withCarousel ? (
              <RestaurantSlider
                images={images}
                restaurantName={restaurant.name}
                withFullGallery={false}
                withControls={false}
                autoPlay={true}
                autoHeight={false}
                mouseTracking={false}
                renderItem={renderSliderItem}
              />
            ) : (
              <Image loader={imgProxyLoader} layout='fill' src={images[0]?.preview} objectFit='cover' objectPosition='center' />
            )}
          </ImageContainer>
          <Details>
            <FestivalName fontWeight='semiBold'>{fer.festivalEdition.festival.name}</FestivalName>
            <ProductPageTitle hideFavourite={true} restaurant={restaurant} />
            {isTablet && (
              <>
                <FormattedPrice price={price} withGuest={true} />
                {variant === 'checkout' ? (
                  <Button
                    color={status ? 'secondary' : 'orange'}
                    width='full'
                    label={
                      status
                        ? ['#trash-full', gt.tp('Button', 'Remove from reservation')]
                        : ['#circle-add', gt.tp('Button', 'Add to reservation')]
                    }
                    onClick={handleClick}
                  />
                ) : (
                  <Button onClick={openSlotSelectModal} label={gt.tp('FestivalModal', 'Book a table')} color='orange' width='full' />
                )}
              </>
            )}
          </Details>
          <Menus>
            {contentFromPartners && <PartnersBox>{contentFromPartners}</PartnersBox>}
            {menus.map((menu, i) => (
              <div key={menu.id}>
                <MenuLabel>{formatMenuLabel(i, menu.label, forceLabels)}</MenuLabel>
                {festivalMenuPreface}
                <Menu menu={menu} withTitle={false} index={i} forceLabels={forceLabels} />
              </div>
            ))}
            {variant !== 'checkout' && (
              <ProductDetailsPanel defaultShow={['chefsMenuDescription']} restaurant={restaurant} sections={['restaurant']} />
            )}
          </Menus>
        </Profile>

        {!isTablet && variant === 'checkout' ? (
          <CheckoutCard>
            <UpperHeading>{gt.tp('Checkout', 'Limited offer')}</UpperHeading>
            <Heading5 fontWeight='semiBold'>
              {gt.tp(
                'Checkout',
                `Turn your restaurant reservation
                  into a unique restaurant experience.`
              )}
            </Heading5>
            <Heading6 fontWeight='regular'>
              {gt.tp(
                'Checkout',
                `This special offer applies to all guests in your reservation,
                  providing them with access to festival dishes, an extraordinary atmosphere and
                  unforgettable attractions.`
              )}
            </Heading6>
            <Heading5 fontWeight='regular'>
              <strong>{formatPrice(price)}</strong> {gt.tp('Checkout', '/ Guest')}
            </Heading5>
            <Button
              color={status ? 'secondary' : 'orange'}
              width={isMobile ? 'full' : 'fit'}
              label={
                status
                  ? ['#trash-full', gt.tp('Button', 'Remove from reservation')]
                  : ['#circle-add', gt.tp('Button', 'Add to reservation')]
              }
              onClick={handleClick}
            />
          </CheckoutCard>
        ) : !isTablet ? (
          slotSelect
        ) : null}
      </Grid>
    </Wrapper>
  )
}

export default FERModalView
