import styled from '@emotion/styled'

import { mediaQueryUp } from '~/theme/utils/grid'

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`

export const PositionWrapper = styled.div`
  display: flex;
  gap: 12px;
  flex-direction: column;

  ${mediaQueryUp('m')} {
    gap: 1.25rem;
  }
`

export const Title = styled.h2`
  font-size: ${({ theme }) => theme.text.fontSize.l};

  ${mediaQueryUp('m')} {
    font-size: ${({ theme }) => theme.text.fontSize.xl};
  }
`

export const Description = styled.p`
  color: ${({ theme }) => theme.colors.gray[600]};
  font-size: ${({ theme }) => theme.text.fontSize.s};

  ${mediaQueryUp('l')} {
    max-width: calc(100% - 256px - 12px);
  }
`

export const Content = styled(Description)`
  color: ${({ theme }) => theme.colors.gray[900]};
  max-width: 500px;
  white-space: pre-wrap;
  font-size: ${({ theme }) => theme.text.fontSize.l};
  line-height: 25.5px;
`
