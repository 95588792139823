// @ts-strict-ignore
import { Dispatch, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { createPortal } from 'react-dom'

import { AutoplacementData } from '../Tooltip/TooltipTypes'

import { Trigger, Wrapper } from './ShowOnTriggerShards'
import { ShowOnTriggerPlacement } from './ShowOnTriggerTypes'
import { autoLocate } from './ShowOnTriggerUtils'

interface Props {
  placement: ShowOnTriggerPlacement
  trigger: React.ReactNode
  content: React.ReactNode
  contentMinWidthAsTrigger?: boolean
  contentAsTrigger?: boolean
  type?: 'click' | 'hover' | 'programmatic'
  show?: boolean
  onClickOutside?(): void
  triggerIndicator?: React.ReactNode
  autoplacement?: AutoplacementData
  setAutoplacement?: Dispatch<AutoplacementData>
  verticalOffset?: number
  scrollTarget?: HTMLElement | null
}

const ShowOnTrigger: React.FC<Props> = ({
  placement,
  content,
  trigger,
  triggerIndicator,
  type = 'hover',
  autoplacement,
  setAutoplacement,
  onClickOutside,
  show: programmaticShow,
  contentMinWidthAsTrigger,
  contentAsTrigger,
  verticalOffset,
  scrollTarget = typeof window !== 'undefined' ? window : undefined,
}) => {
  const [show, setShow] = useState<boolean>(programmaticShow || false)
  const [rect, setRect] = useState<DOMRect>(undefined)
  const [mounted, setMounted] = useState(undefined)
  const target = useRef(null)
  const wrapper = useRef(null)
  const contentWidth = useMemo(() => (rect ? wrapper.current?.getBoundingClientRect()?.width : 0), [wrapper, rect])

  const handleRedraw = useCallback(() => {
    if (!target?.current || !show) {
      return
    }

    const newRect = target.current.getBoundingClientRect().toJSON()
    const isModal = document && document.getElementById('modal_overlay_id')?.contains(target.current)

    setRect({
      ...newRect,
      top: isModal ? newRect.top : newRect.top + window.scrollY,
    })
  }, [show])

  useEffect(() => {
    if (placement === 'auto' && wrapper.current && rect) {
      const { width, height } = wrapper.current.getBoundingClientRect()
      const location = autoLocate(rect, { width, height }, { width: window.innerWidth, height: window.innerHeight })
      setAutoplacement(location)
    }
  }, [placement, rect, wrapper])

  useEffect(() => {
    const handleClickOutside = event => {
      const insideTarget = target.current && target.current.contains(event.target)
      const insideWrapper = wrapper.current && wrapper.current.contains(event.target)

      if (insideTarget || insideWrapper) {
        return
      }

      if (type !== 'programmatic') {
        setShow(false)
      }

      onClickOutside && onClickOutside()
    }

    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [target, wrapper, type])

  useEffect(() => {
    window.addEventListener('resize', handleRedraw)
    return () => window.removeEventListener('resize', handleRedraw)
  }, [show])

  useEffect(() => {
    scrollTarget.addEventListener('scroll', handleRedraw)
    return () => scrollTarget.removeEventListener('scroll', handleRedraw)
  }, [handleRedraw, show])

  useEffect(() => {
    window.addEventListener('load', handleRedraw)
    return () => window.removeEventListener('load', handleRedraw)
  }, [show])

  useEffect(() => {
    setMounted(true)
    return () => setMounted(false)
  }, [])

  const handleMouseLeave = e => {
    const { top, bottom, left, right } = wrapper.current.getBoundingClientRect()
    if (e.clientX > right || e.clientX < left || e.clientY > bottom || e.clientY < top) {
      setShow(false)
    }
  }

  useEffect(() => setShow(programmaticShow), [programmaticShow])

  useEffect(handleRedraw, [show])

  if (!trigger) {
    return null
  }

  return (
    <>
      <Trigger
        onClick={type === 'click' ? () => setShow(v => !v) : null}
        onMouseOver={type === 'hover' ? () => setShow(true) : null}
        onMouseLeave={type === 'hover' ? handleMouseLeave : null}
        ref={target}>
        {trigger}
      </Trigger>
      {mounted &&
        show &&
        createPortal(
          <Wrapper
            ref={wrapper}
            onMouseLeave={type === 'hover' ? () => setShow(false) : null}
            rect={rect}
            verticalOffset={verticalOffset}
            contentWidth={contentWidth}
            placementData={autoplacement ?? placement}
            show={show}
            showOnHover={type === 'hover'}
            style={contentMinWidthAsTrigger && rect && { minWidth: rect.width }}
            onClick={type === 'click' && contentAsTrigger ? () => setShow(v => !v) : null}>
            {content}
            {triggerIndicator}
          </Wrapper>,
          document.querySelector('#modal_overlay_id') || document.querySelector('#__next') || document.querySelector('#root')
        )}
    </>
  )
}

export default ShowOnTrigger
