import { MouseEventHandler, useState } from 'react'

import { LeanFerFragment } from '~/generated/graphql'
import Pill from '~/shared/atoms/Pill'
import Tooltip from '~/shared/atoms/Tooltip'
import { ModalProps } from '~/shared/molecules/Modal'
import { CloseIcon as ModalCloseIcon } from '~/shared/molecules/Modal/ModalShards'

import { IconContainer, MenuIcon, MenuIconWrapper, PillContainer, TooltipWrapper } from './RestaurantCardShards'
import RestaurantMenu from './RestaurantMenu'

export const loginModalProps: ModalProps = {
  hasVisibleOverlay: true,
  hasClickableOverlay: false,
  closeIconPosition: 'outside' as const,
  fillScreenOnMobile: true,
  handleClick: () => null,
  position: 'fixed' as const,
  size: 'big' as const,
  iconSize: 'big' as const,
}

export const LoadingPills = () => (
  <PillContainer>{[1, 2, 3, 4, 5]?.map(slot => <Pill key={slot} loading={true} text={slot.toString()} />)}</PillContainer>
)

export const CloseIcon = ({ onClick }: { onClick: MouseEventHandler<HTMLDivElement> }) => {
  return (
    <IconContainer onClick={onClick} isOutside={false} iconSize={'big'}>
      <ModalCloseIcon icon='x' isOutside={false} />
    </IconContainer>
  )
}

export const MenuTooltip = ({ menus, forceLabels }: { menus: LeanFerFragment['menus']; forceLabels: boolean }) => {
  const [showMenuModal, setShowMenuModal] = useState(false)

  return (
    <TooltipWrapper
      onMouseEnter={() => setShowMenuModal(true)}
      onMouseLeave={() => setShowMenuModal(false)}
      onClick={e => {
        setShowMenuModal(true)
        e.stopPropagation()
      }}>
      <Tooltip
        size='big'
        placement={'auto'}
        show={showMenuModal}
        label={
          <>
            <CloseIcon
              onClick={e => {
                e.stopPropagation()
                setShowMenuModal(false)
              }}
            />
            <RestaurantMenu menus={menus} forceLabels={forceLabels} />
          </>
        }
        trigger={'programmatic'}>
        <MenuIconWrapper onClick={e => e.preventDefault()}>
          <MenuIcon icon='book-new' />
        </MenuIconWrapper>
      </Tooltip>
    </TooltipWrapper>
  )
}
